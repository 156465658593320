<template>
  <div class="">
    <el-form :inline="true">
        <el-form-item label="教练">
        <coachSelect :coachid.sync="coachid"></coachSelect>
      </el-form-item>
      <el-form-item label="违规类型">
        <el-select v-model="type" placeholder="选择违规类型" clearable>
          <el-option
            v-for="(v, i) in typeMap"
            :key="i"
            :label="v.label"
            :value="v.value"
          ></el-option>
        </el-select>
      </el-form-item>
       <el-form-item label="处理时间">
        <el-date-picker
          v-model="createTime"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #real_name="{ row }">
        <div>
          <link-to :to="{ name: 'coachDetails', query: { id: row.coach_id } }">
            {{ row.map.coach ? row.map.coach.real_name : '' }}</link-to
          >
        </div>
      </template>

      <template #context="{ row }">
        <div>
          <div class="ell">{{ row.context }}</div>
        </div>
      </template>
      <template #pic="{ row }">
        <div v-if="row.pic">
          <el-image
            style="width: 100px; height: 100px"
            :src="row.pic.split(',')[0]"
          ></el-image>
        </div>
      </template>
      <template #type="{ row }">
        <div>
            {{row.status === 1 ? '解除':''}}
          {{ getLabel("typeMap", row.type) }}
        </div>
      </template>
      <template #submit_type="{ row }">
        <div>
          {{ getLabel("submitTypeMap", row.submit_type) }}
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag v-if="row.status === 0" type="info">已处理</el-tag>
          <el-tag v-if="row.status === 1" type="success">已处理</el-tag>
            <el-tag v-if="row.status === 2" type="primary">已取消</el-tag>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button
            v-if="row.status === 0"
            type="primary"
            @click="openHandler(row)"
            >取消惩罚</el-button
          >
        </div>
      </template>
    </auto-table>

    <!-- 处理违规 -->
    <el-dialog title="处理违规" :visible.sync="handlerShow" width="50%">
      <el-form>
        <el-form-item label="处理方式">
          {{ getLabel("typeMap", changeRecord.type) }}
        </el-form-item>
        <el-form-item label="惩罚时间">
          {{ changeRecord.start_time }}-{{ changeRecord.end_time }}
        </el-form-item>
        <el-form-item label="解除说明">
          <el-input
            type="textarea"
            :rows="5"
            placeholder="解除违规惩罚后给教练发送的提示消息"
            v-model="form.remark"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitHandler">取消违规惩罚</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handlerShow = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import coachSelect from '../../../components/select/coachSelect.vue';

export default {
    components:{
        coachSelect
    },
  data() {
    return {
        createTime:[],
      DataList: [],
      Option: [
        { name: "教练", value: "real_name", type: "custom" },
        { name: "城市", value: "map.coach.city" },
        { name: "类型", value: "type", type: "custom" },
        { name: "处理人", value: "handler_name"},
        { name: "类型", value: "status", type: "custom" },
        { name: "处理时间", value: "update_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      type: "",
      submint_type: "",
      typeMap: [
        {
          label: "无处罚",
          value: "none",
        },
         {
          label: "禁言",
          value: "muted",
        },
        {
          label: "取消惩罚",
          value: "cancel",
        },
      ],
      coachid:'',
      changeRecord: {},
      handlerShow: false,
      form: {},
      adminInfo: JSON.parse(localStorage.getItem("managerInfo")),
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    submitHandler(){
         let data = {
            handler_id:this.adminInfo.id,
              handler_name:this.adminInfo.name,
              id:this.changeRecord.id,
              coach_id:this.changeRecord.coach_id,
              type:this.changeRecord.type,
              remark:this.form.remark,
              status:1,
              vid:this.changeRecord.vid
        }

        this.$post("/user/violationHandler/insert",data).then(res=>{
                this.form = {}
                this.handlerShow = false
                this.getList(1)
        })
    },
    openHandler(row) {
      this.changeRecord = row;
      this.handlerShow = true;
    },
    getLabel(list, v) {
      for (let index = 0; index < this[list].length; index++) {
        if (this[list][index].value === v) {
          return this[list][index].label;
        }
      }
    },
    load() {
      if (this.DataList.length == 0) {
        this.getList(1);
      }
    },
    userDetail(row) {
      this.$router.push({ name: "userDetails", params: { id: row.user_id } });
    },
    //记录
    getList(v) {
      let page = v || 1;
let params = {
          currentPage: page,
          pageSize: 10,
          type: this.type || null,
          coach_id :this.coachid || null
        }
       if (this.createTime !== null && this.createTime.length > 0) {
        params.start_time = this.query.createTime[0] + " 00:00:00";
        params.end_time = this.query.createTime[1] + " 23:59:59";
      }
      this.$axios({
        url: "/user/violationHandler/queryManagerListPage",
        params
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style scoped>
.ell {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>