var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"教练"}},[_c('coachSelect',{attrs:{"coachid":_vm.coachid},on:{"update:coachid":function($event){_vm.coachid=$event}}})],1),_c('el-form-item',{attrs:{"label":"违规类型"}},[_c('el-select',{attrs:{"placeholder":"选择违规类型","clearable":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},_vm._l((_vm.typeMap),function(v,i){return _c('el-option',{key:i,attrs:{"label":v.label,"value":v.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"处理时间"}},[_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","value-format":"yyyy-MM-dd","start-placeholder":"开始日期","end-placeholder":"结束日期"},model:{value:(_vm.createTime),callback:function ($$v) {_vm.createTime=$$v},expression:"createTime"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("搜索")])],1)],1),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"real_name",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('link-to',{attrs:{"to":{ name: 'coachDetails', query: { id: row.coach_id } }}},[_vm._v(" "+_vm._s(row.map.coach ? row.map.coach.real_name : ''))])],1)]}},{key:"context",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('div',{staticClass:"ell"},[_vm._v(_vm._s(row.context))])])]}},{key:"pic",fn:function(ref){
var row = ref.row;
return [(row.pic)?_c('div',[_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":row.pic.split(',')[0]}})],1):_vm._e()]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(row.status === 1 ? '解除':'')+" "+_vm._s(_vm.getLabel("typeMap", row.type))+" ")])]}},{key:"submit_type",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(" "+_vm._s(_vm.getLabel("submitTypeMap", row.submit_type))+" ")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.status === 0)?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("已处理")]):_vm._e(),(row.status === 1)?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("已处理")]):_vm._e(),(row.status === 2)?_c('el-tag',{attrs:{"type":"primary"}},[_vm._v("已取消")]):_vm._e()],1)]}},{key:"handler",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.status === 0)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.openHandler(row)}}},[_vm._v("取消惩罚")]):_vm._e()],1)]}}])}),_c('el-dialog',{attrs:{"title":"处理违规","visible":_vm.handlerShow,"width":"50%"},on:{"update:visible":function($event){_vm.handlerShow=$event}}},[_c('el-form',[_c('el-form-item',{attrs:{"label":"处理方式"}},[_vm._v(" "+_vm._s(_vm.getLabel("typeMap", _vm.changeRecord.type))+" ")]),_c('el-form-item',{attrs:{"label":"惩罚时间"}},[_vm._v(" "+_vm._s(_vm.changeRecord.start_time)+"-"+_vm._s(_vm.changeRecord.end_time)+" ")]),_c('el-form-item',{attrs:{"label":"解除说明"}},[_c('el-input',{attrs:{"type":"textarea","rows":5,"placeholder":"解除违规惩罚后给教练发送的提示消息"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitHandler}},[_vm._v("取消违规惩罚")])],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.handlerShow = false}}},[_vm._v("关 闭")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }