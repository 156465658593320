<template>
    <div class="container">
        <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="教练违规列表" name="record">
        <violationRecord ref="record"></violationRecord>
    </el-tab-pane>
    <el-tab-pane label="违规处理" name="handler">
        <violationHandler ref="handler"></violationHandler>
    </el-tab-pane>
  </el-tabs>
    </div>
</template>

<script>
import violationHandler from './components/violationHandler.vue';
import violationRecord from './components/violationRecord.vue';
export default {
  components:{
violationHandler,
violationRecord
  },
    data() {
        return {
           activeName:'record' 
        };
    },

    mounted() {
        
    },

    methods: {
        handleClick(tab){
            this.$refs[tab.name].load()
        }
    },
};
</script>

<style lang="scss" scoped>

</style>